import day from 'dayjs'
import utc from 'dayjs/plugin/utc'

day.extend(utc)

import { IContract, IContractLog } from '@/js/utils/types'
import dayjs from "dayjs";

function getDateDifferenceInMilliseconds(from: string, to: string): number {
    const datePartsFrom = from.split('-')
    const datePartsTo = to.split('-')

    const fromDate = new Date(parseInt(datePartsFrom[2]), parseInt(datePartsFrom[1]) - 1, parseInt(datePartsFrom[0]))
    const toDate = new Date(parseInt(datePartsTo[2]), parseInt(datePartsTo[1]) - 1, parseInt(datePartsTo[0]))

    const fromTimestamp = fromDate.getTime()
    const toTimestamp = toDate.getTime()

    return toTimestamp - fromTimestamp
}

export const isAtLeastOneMonth = (from: string, to: string): boolean => {
    const timeDiff = getDateDifferenceInMilliseconds(from, to)

    const MINIMUM_DAY_IN_A_MONTH = 27
    const oneMonthInMillis = MINIMUM_DAY_IN_A_MONTH * 24 * 60 * 60 * 1000

    return timeDiff >= oneMonthInMillis
}

export const isLessThanAYear = (from: string, to: string): boolean => {
    const timeDiff = getDateDifferenceInMilliseconds(from, to)

    const ONE_YEAR_IN_MILLIS = 365 * 24 * 60 * 60 * 1000

    return timeDiff <= ONE_YEAR_IN_MILLIS
}

export function timeToMinutes(time: string | undefined) {
    if (time) {
        const [hours, minutes] = time.split(':').map(Number)
        return hours * 60 + minutes
    } else return 0
}

export function minutesToTime(minutes: number) {
    const hours = Math.floor(minutes / 60)
    const remainingMinutes = minutes % 60

    const formattedHours = String(hours).padStart(2, '0')
    const formattedMinutes = String(remainingMinutes).padStart(2, '0')

    return `${formattedHours}:${formattedMinutes}`
}

export function getLabelDate(
    timestamp: string | number,
    options:
        | {
              custom?: {
                  year?: 'numeric' | '2-digit'
                  month?: 'numeric' | '2-digit'
                  day?: 'numeric' | '2-digit'
                  hour?: 'numeric' | '2-digit'
                  minute?: 'numeric' | '2-digit'
              }
              onlyDate: boolean
              showUtc?: boolean
          }
        | undefined = undefined
) {
    const format = options?.onlyDate ? 'DD/MM/YY' : 'DD/MM/YY, HH:mm'

    return options?.showUtc ? day.utc(timestamp).format(format) : day(timestamp).format(format)
}

export function getContractPeriod(item: IContractLog | IContract) {
    return (
        'Periodo: ' +
        getLabelDate(item.from * 1000, { onlyDate: true, showUtc: true }) +
        ' - ' +
        getLabelDate(item.to * 1000, { onlyDate: true, showUtc: true })
    )
}

export const timestampToApiFormat = (date, includeDays = false) =>
    dayjs.unix(date).format(includeDays ? 'DD/MM/YYYY' : 'MM/YYYY')
